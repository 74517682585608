<template>
  <StoresFilterCategoriesParentsFilterMultiLevel :categories="categories">
    <template
      #categoriesParent="{
        goToCategory,
        isActiveCategory,
        categoriesCategoryStyle
      }"
    >
      <MevcutSwiper
        :key="`categorie-CategorySlider`"
        :items="categoriesCategoryStyle"
        :auto-slides="true"
        :auto-play="false"
      >
        <template #slide="{ item, index }">
          <div :key="`store-filter-order-${item.name}`">
            <button class="text-xs mx-1 mt-5" @click="goToCategory(index)">
              <div class="w-12 xs:w-16 sm:w-20 md:28 lg:w-32 mx-auto">
                <NuxtImg
                  style="border-radius: 20px"
                  provider="cacheIpx"
                  preset="modified"
                  loading="lazy"
                  :src="item.image"
                  :alt="item.name"
                  width="128"
                  height="128"
                  class="mx-auto aspect-square object-cover border"
                />
                <div
                  class="h-1 w-8/12 mx-auto"
                  :class="{
                    'border-b-2  border-nav_color ': isActiveCategory(index)
                  }"
                ></div>
              </div>

              <div
                class="py-1 pb-1"
                :class="{
                  'text-text_alt_color': isActiveCategory(index) && isDomain
                }"
              >
                <ClientOnly>{{ item.name }}</ClientOnly>
              </div>
            </button>
          </div>
        </template>
      </MevcutSwiper>
    </template>
  </StoresFilterCategoriesParentsFilterMultiLevel>
</template>

<script setup lang="ts">
import type { Category } from '~~/composables/useMenuModel'

withDefaults(
  defineProps<{
    categories: Category[]
  }>(),
  {}
)
const isDomain = useIsDomain()
</script>
